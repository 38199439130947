"use client";
import React, { useContext, useEffect } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import Icon from "@/components/primitives/icon";
import Link from "next/link";
import { useRouter } from "next-nprogress-bar";

import { useClerk } from "@clerk/clerk-react";
import { useUser } from "@clerk/nextjs";
import { OddsFormat } from "common";
import { AuthContext } from "@/app/(public)/drawer";
import list from "@/components/primitives/list.module.css";
import { useOddsFormat } from "@/utils/providers/odds-format";
import { useBettor } from "@/utils/providers/bettor";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import { useMenu } from "@/utils/providers/menu";
import { createProfileSlug } from "@/utils/url";
import Avatar from "../primitives/avatar";

async function getProfile(user_id: string) {
  const res = await fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/api/users/${user_id}/profiles`
  );

  if (!res.ok) {
    throw new Error("Failed to fetch profile");
  }
  return res.json();
}

function UserDropdown() {
  const router = useRouter();
  const { setAuth, closeAuth } = useContext(AuthContext);

  const { signOut } = useClerk();
  const { isSignedIn, user, isLoaded } = useUser();
  const { bettor } = useBettor();
  // const [avatar, setAvatar] = React.useState<string | undefined>(undefined);
  const [open, setOpen] = React.useState<boolean>(false);
  const [menu, setMenu] = React.useState<string>("main");
  const [selectedTheme, setSelectedTheme] = React.useState<string>("light");
  const { oddsFormat, setOddsFormat } = useOddsFormat();
  const { mobileMenuOpen, mobileProfileMenuOpen, setMobileProfileMenuOpen } =
    useMenu();
  // const [oddFormat, setOddFormat] = React.useState("american");
  // const [highlightedOddFormat, setHighlightedOddFormat] = React.useState<string | undefined>("true");

  let menuHeight: string = "h-auto";
  let mobileMenuHeight: string = "h-auto";
  switch (menu) {
    case "odd-format":
      menuHeight = "h-[184px]";
      mobileMenuHeight = "h-[262px]";
      break;
    case "theme-select":
      menuHeight = "h-[136px]";
      mobileMenuHeight = "h-[204px]";
      break;
    default:
      if (bettor) {
        if (bettor.details.is_admin) {
          menuHeight = "h-[307.2px]";
          mobileMenuHeight = "h-[451.2px]";

          break;
        }
        menuHeight = "h-[256px]";
        mobileMenuHeight = "h-[376px]";
      } else {
        menuHeight = "h-[208px]";
        mobileMenuHeight = "h-[304px]";
      }
  }

  // useEffect(() => {
  //   if (user?.id) {
  //     getProfile(user.id).then((data: Bettor) => {
  //       setAvatar(data.avatar);
  //     });
  //   }
  // }, [isSignedIn, isLoaded]);

  function handleMenuChange(e: any, value: string) {
    e.preventDefault();
    setMenu(value);
  }

  useEffect(() => {
    if (mobileMenuOpen && mobileProfileMenuOpen) {
      setMobileProfileMenuOpen(false);
    }
    if (!mobileProfileMenuOpen) {
      setMenu("main");
    }
  }, [mobileMenuOpen, mobileProfileMenuOpen]);

  // const displayName =
  //   (user?.unsafeMetadata.displayName as string) || user?.username;

  return (
    <>
      <div className="hidden desktop-grid:flex items-center desktop-grid:justify-end">
        <DropdownMenu.Root
          open={open}
          onOpenChange={(open) => {
            setOpen((prev) => !prev);
            if (!open) {
              setMenu("main");
            }
          }}
        >
          <DropdownMenu.Trigger
            onChange={() => setOpen}
            className="outline-none"
          >
            {isSignedIn && bettor?.details ? (
              <div className="flex gap-2 items-center justify-center">
                <Avatar img={bettor?.details.avatar_url}></Avatar>
                <span className="text-title-lg-medium text-white hidden desktop-menu:flex">
                  {bettor.details.display_name}
                </span>
                <div className="hidden desktop-menu:flex">
                  <Icon name="arrow-button-down" className="fill-white" />
                </div>
              </div>
            ) : (
              <div className="flex gap-2 items-center justify-center">
                <div className="w-8 h-8 flex justify-center items-center bg-secondary-300 rounded-full">
                  <Icon name="user" className="fill-white text-white" />
                </div>
                <Icon name="arrow-button-down" className="fill-white" />
              </div>
            )}
          </DropdownMenu.Trigger>
          <DropdownMenu.Portal>
            <DropdownMenu.Content
              align="end"
              sideOffset={8}
              className="rounded-md bg-light shadow-elevation-300 focus:shadow-elevation-400 will-change-[transform,opacity] data-[state=open]:data-[side=top]:animate-slideDownAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade z-50 w-[12.5rem] p-2 bg-[url('/images/mobile-bg-pattern.png')] bg-no-repeat bg-center bg-cover"
            >
              <div
                className={`relative overflow-hidden w-[184px] ${menuHeight}`}
              >
                {/* DROPDOWN MENU ODDS FORMAT */}
                <div
                  className={`absolute inset-0 w-full h-full transition-all duration-150 ease-in-out ${
                    menu !== "main"
                      ? "-translate-x-full pointer-events-none"
                      : "translate-x-0 pointer-events-auto"
                  }`}
                >
                  {/* DROPDOWN MENU SIGNED IN */}
                  {isLoaded && isSignedIn && bettor?.details ? (
                    <>
                      <Link
                        className="w-full"
                        href={createProfileSlug(bettor?.details.display_name)}
                        onClick={() => {
                          setOpen(false);
                        }}
                      >
                        <Item label="View Profile" icon="user" link />
                      </Link>
                      <Link
                        href={"/account"}
                        className="w-full"
                        onClick={() => {
                          setOpen(false);
                        }}
                      >
                        <Item label="Account Settings" icon="cog" link />
                      </Link>
                      {bettor?.details.is_admin && (
                        <Link
                          href={"/dashboard"}
                          className="w-full"
                          onClick={() => {
                            setOpen(false);
                          }}
                        >
                          <Item
                            label="Admin Dashboard"
                            icon="layout-grid"
                            iconSize="xs"
                            link
                          />
                        </Link>
                      )}
                      <button
                        className="w-full"
                        onClick={(e) => handleMenuChange(e, "odd-format")}
                      >
                        <Item
                          label="Odds Type"
                          subLabel={oddsFormat}
                          icon="coins"
                          iconSize="sm"
                        />
                      </button>
                      <button
                        className="w-full"
                        onClick={(e) => handleMenuChange(e, "theme-select")}
                      >
                        <Item
                          label="Theme"
                          subLabel={selectedTheme}
                          icon="theme"
                          iconSize="sm"
                        />
                      </button>
                      <button
                        className="w-full"
                        onClick={() => {
                          signOut(() => router.push("/"));
                          setOpen(false);
                        }}
                      >
                        <Item label="Logout" icon="logout" iconSize="xs" />
                      </button>
                    </>
                  ) : (
                    // DROPDOWN MENU SIGNED OUT
                    <>
                      <button
                        className="w-full"
                        onClick={() => {
                          setOpen(false);
                          setAuth("login");
                        }}
                      >
                        <Item label="Login" icon="login" iconSize="sm" />
                      </button>
                      <button
                        className="w-full"
                        onClick={() => {
                          setOpen(false);
                          setAuth("register");
                        }}
                      >
                        <Item label="Create Account" icon="user" />
                      </button>
                      <button
                        className="w-full"
                        onClick={(e) => {
                          handleMenuChange(e, "odd-format");
                        }}
                      >
                        <Item
                          label="Odds Type"
                          subLabel={oddsFormat}
                          icon="coins"
                          iconSize="sm"
                        />
                      </button>
                      <button
                        className="w-full"
                        onClick={(e) => handleMenuChange(e, "theme-select")}
                      >
                        <Item
                          label="Theme"
                          subLabel={selectedTheme}
                          icon="theme"
                          iconSize="sm"
                        />
                      </button>
                    </>
                  )}
                </div>
                <div
                  id="dropdown-odds-format-menu-no-auth"
                  className={`absolute inset-0 w-full h-full transition-all duration-150 ease-in-out ${
                    menu === "odd-format"
                      ? "translate-x-0 pointer-events-auto"
                      : "translate-x-full pointer-events-none"
                  }`}
                >
                  <button
                    className="w-full"
                    onClick={(e) => handleMenuChange(e, "main")}
                  >
                    <Item label="Back" icon="caret-left" iconSize="xs" />
                  </button>
                  <button
                    className={`${list.listItem} w-full`}
                    onClick={(e) => {
                      setOddsFormat("american");
                      handleMenuChange(e, "main");
                      setOpen(false);
                    }}
                  >
                    American -200
                    {oddsFormat === "american" && (
                      <div className={list.indicator}>
                        <Icon
                          name="checkmark"
                          size="md"
                          className="transition-none"
                        ></Icon>
                      </div>
                    )}
                  </button>
                  <button
                    className={`${list.listItem} w-full`}
                    onClick={(e) => {
                      setOddsFormat("decimal");
                      handleMenuChange(e, "main");
                      setOpen(false);
                    }}
                  >
                    Decimal 1.5
                    {oddsFormat === "decimal" && (
                      <div className={list.indicator}>
                        <Icon
                          name="checkmark"
                          size="md"
                          className="transition-none"
                        ></Icon>
                      </div>
                    )}
                  </button>
                  <button
                    className={`${list.listItem} w-full`}
                    onClick={(e) => {
                      setOddsFormat("fraction");
                      handleMenuChange(e, "main");
                      setOpen(false);
                    }}
                  >
                    Fraction 1/2
                    {oddsFormat === "fraction" && (
                      <div className={list.indicator}>
                        <Icon
                          name="checkmark"
                          size="md"
                          className="transition-none"
                        ></Icon>
                      </div>
                    )}
                  </button>
                </div>
                <div
                  id="dropdown-theme-select-menu-no-auth"
                  className={`absolute inset-0 w-full h-full transition-all duration-150 ease-in-out ${
                    menu === "theme-select"
                      ? "translate-x-0 pointer-events-auto"
                      : "translate-x-full pointer-events-none"
                  }`}
                >
                  <button
                    className="w-full"
                    onClick={(e) => handleMenuChange(e, "main")}
                  >
                    <Item label="Back" icon="caret-left" iconSize="xs" />
                  </button>
                  <button
                    className={`${list.listItem} w-full`}
                    onClick={(e) => {
                      setSelectedTheme("light");
                      handleMenuChange(e, "main");
                      setOpen(false);
                    }}
                  >
                    Light
                    {selectedTheme === "light" && (
                      <div className={list.indicator}>
                        <Icon
                          name="checkmark"
                          size="md"
                          className="transition-none"
                        ></Icon>
                      </div>
                    )}
                  </button>
                  <button
                    disabled
                    className={`${list.listItem} w-full`}
                    onClick={(e) => {
                      setSelectedTheme("dark");
                      setOpen(false);
                      handleMenuChange(e, "main");
                    }}
                  >
                    <span className="text-secondary-500">
                      Dark <span className="italic">(Coming Soon)</span>
                    </span>
                    {selectedTheme === "dark" && (
                      <div className={list.indicator}>
                        <Icon
                          name="checkmark"
                          size="md"
                          className="transition-none"
                        ></Icon>
                      </div>
                    )}
                  </button>
                </div>
              </div>
            </DropdownMenu.Content>
          </DropdownMenu.Portal>
        </DropdownMenu.Root>
      </div>
      <div className="flex items-center desktop-grid:hidden">
        <NavigationMenu.Root orientation="vertical">
          <NavigationMenu.List>
            <NavigationMenu.Item
              // value={"main"}
              className="flex justify-center items-center"
            >
              <NavigationMenu.Trigger
                onPointerLeave={(e) => e.preventDefault()}
                onPointerMove={(e) => e.preventDefault()}
                data-state={mobileProfileMenuOpen ? "open" : "closed"}
                onClick={() => setMobileProfileMenuOpen((prev) => !prev)}
              >
                {isSignedIn && bettor?.details ? (
                  <div className="flex gap-2 items-center justify-center">
                    <Avatar img={bettor?.details.avatar_url}></Avatar>
                    <span className="text-title-lg-medium text-white hidden desktop-menu:flex">
                      {bettor?.details.display_name}
                    </span>
                    <div className="hidden desktop-menu:flex">
                      <Icon name="arrow-button-down" className="fill-white" />
                    </div>
                  </div>
                ) : (
                  <div className="flex gap-2 items-center justify-center">
                    <div className="w-8 h-8 flex justify-center items-center bg-secondary-300 rounded-full">
                      <Icon name="user" className="fill-white text-white" />
                    </div>
                    <Icon name="arrow-button-down" className="fill-white" />
                  </div>
                )}
              </NavigationMenu.Trigger>

              <NavigationMenu.Content
                onPointerDownOutside={(e) => e.preventDefault()}
                className="bg-[url('/images/mobile-bg-pattern.png')] bg-no-repeat bg-center bg-cover pt-3"
              >
                {/* MOBILE PROFILE MENU DRAWER */}
                {/* <div
              id="test1"
                className={`absolute w-full h-full transition-all duration-150 ease-in-out ${
                  menu === "odd-format"
                    ? "-translate-x-[200%]"
                    : "translate-x-0"
                }`}
              ></div> */}
                {isLoaded && isSignedIn && bettor?.details ? (
                  <>
                    <div
                      className={`relative overflow-hidden w-full ${mobileMenuHeight}`}
                    >
                      <div
                        id="mobile-profile-menu-drawer"
                        className={`absolute inset-0 w-full h-full transition-all duration-150 ease-in-out ${
                          menu !== "main"
                            ? "-translate-x-full pointer-events-none"
                            : "translate-x-0 pointer-events-auto"
                        }`}
                      >
                        <Link
                          className="w-full"
                          href={createProfileSlug(bettor?.details.display_name)}
                          onClick={() => {
                            setMobileProfileMenuOpen(false);
                          }}
                        >
                          <DialogItem label="View Profile" icon="user" link />
                        </Link>
                        <Link
                          href={"/account"}
                          className="w-full"
                          onClick={() => {
                            setMobileProfileMenuOpen(false);
                          }}
                        >
                          <DialogItem
                            label="Account Settings"
                            icon="cog"
                            link
                          />
                        </Link>
                        {bettor?.details.is_admin && (
                        <Link
                          href={"/dashboard"}
                          className="w-full"
                          onClick={() => {
                            setMobileProfileMenuOpen(false);
                          }}
                        >
                          <DialogItem
                            label="Admin Dashboard"
                            icon="layout-grid"
                            iconSize="md"
                            link
                          />
                        </Link>
                      )}
                        <button
                          className="w-full"
                          onClick={(e) => {
                            handleMenuChange(e, "odd-format");
                          }}
                        >
                          <DialogItem
                            label="Odds Type"
                            icon="coins"
                            subLabel={oddsFormat}
                          />
                        </button>
                        <button
                          className="w-full"
                          onClick={(e) => {
                            handleMenuChange(e, "theme-select");
                          }}
                        >
                          <DialogItem
                            label="Theme"
                            icon="theme"
                            subLabel={selectedTheme}
                          />
                        </button>
                        <button
                          className="w-full"
                          onClick={() => {
                            signOut(() => router.push("/"));
                            setMobileProfileMenuOpen(false);
                          }}
                        >
                          <DialogItem label="Logout" icon="logout" />
                        </button>
                      </div>
                    </div>
                    <div
                      id="mobile-profile-menu-odds-format"
                      className={`absolute inset-0 pt-3 w-full h-full transition-all duration-150 ease-in-out ${
                        menu === "odd-format"
                          ? "translate-x-0 pointer-events-auto"
                          : "translate-x-full pointer-events-none"
                      }`}
                    >
                      <button
                        className="w-full"
                        onClick={(e) => handleMenuChange(e, "main")}
                      >
                        <DialogItem label="Back" icon="caret-left" />
                      </button>
                      <button
                        className={`w-full`}
                        onClick={() => {
                          setOddsFormat("american");
                          setMobileProfileMenuOpen(false);
                        }}
                      >
                        <DialogItem
                          label="American -200"
                          highlighted={oddsFormat === "american"}
                        >
                          {oddsFormat === "american" && (
                            <div className={list.listIndicator}>
                              <Icon
                                name="checkmark"
                                size="md"
                                className="transition-none"
                              ></Icon>
                            </div>
                          )}
                        </DialogItem>
                      </button>
                      <button
                        className={`w-full`}
                        onClick={() => {
                          setOddsFormat("decimal");
                          setMobileProfileMenuOpen(false);
                        }}
                      >
                        <DialogItem
                          label="Decimal 1.5"
                          highlighted={oddsFormat === "decimal"}
                        >
                          {oddsFormat === "decimal" && (
                            <div className={list.listIndicator}>
                              <Icon
                                name="checkmark"
                                size="md"
                                className="transition-none"
                              ></Icon>
                            </div>
                          )}
                        </DialogItem>
                      </button>
                      <button
                        className={`w-full`}
                        onClick={() => {
                          setOddsFormat("fraction");
                          setMobileProfileMenuOpen(false);
                        }}
                      >
                        <DialogItem
                          label="Fraction 1/2"
                          highlighted={oddsFormat === "fraction"}
                        >
                          {oddsFormat === "fraction" && (
                            <div className={list.listIndicator}>
                              <Icon
                                name="checkmark"
                                size="md"
                                className="transition-none"
                              ></Icon>
                            </div>
                          )}
                        </DialogItem>
                      </button>
                    </div>
                    <div
                      id="mobile-profile-menu-theme-select"
                      className={`absolute inset-0 pt-3 w-full h-full transition-all duration-150 ease-in-out ${
                        menu === "theme-select"
                          ? "translate-x-0 pointer-events-auto"
                          : "translate-x-full pointer-events-none"
                      }`}
                    >
                      <button
                        className="w-full"
                        onClick={(e) => handleMenuChange(e, "main")}
                      >
                        <DialogItem label="Back" icon="caret-left" />
                      </button>
                      <button
                        className={`w-full`}
                        onClick={() => {
                          setSelectedTheme("light");
                          setMobileProfileMenuOpen(false);
                        }}
                      >
                        <DialogItem
                          label="Light"
                          highlighted={selectedTheme === "light"}
                        >
                          {selectedTheme === "light" && (
                            <div className={list.listIndicator}>
                              <Icon
                                name="checkmark"
                                size="md"
                                className="transition-none"
                              ></Icon>
                            </div>
                          )}
                        </DialogItem>
                      </button>
                      <button
                        disabled
                        className={`w-full`}
                        onClick={() => {
                          setSelectedTheme("dark");
                          setMobileProfileMenuOpen(false);
                        }}
                      >
                        <DialogItem
                          comingSoon
                          label="Dark"
                          highlighted={selectedTheme === "dark"}
                        >
                          {selectedTheme === "dark" && (
                            <div className={list.listIndicator}>
                              <Icon
                                name="checkmark"
                                size="md"
                                className="transition-none"
                              ></Icon>
                            </div>
                          )}
                        </DialogItem>
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className={`relative overflow-hidden w-full ${mobileMenuHeight}`}
                    >
                      <div
                        id="mobile-profile-menu-drawer-no-auth"
                        className={`absolute inset-0 w-full h-full transition-all duration-150 ease-in-out ${
                          menu !== "main"
                            ? "-translate-x-full pointer-events-none"
                            : "translate-x-0 pointer-events-auto"
                        }`}
                      >
                        <button
                          className="w-full"
                          onClick={() => {
                            setAuth("login");
                            setMobileProfileMenuOpen(false);
                          }}
                        >
                          <DialogItem label="Login" icon="login" />
                        </button>
                        <button
                          className="w-full"
                          onClick={() => {
                            setAuth("register");
                            setMobileProfileMenuOpen(false);
                          }}
                        >
                          <DialogItem label="Create Account" icon="user" />
                        </button>
                        <button
                          className="w-full"
                          onClick={(e) => handleMenuChange(e, "odd-format")}
                        >
                          <DialogItem
                            label="Odds Type"
                            icon="coins"
                            subLabel={oddsFormat}
                          />
                        </button>
                        <button
                          className="w-full"
                          onClick={(e) => {
                            handleMenuChange(e, "theme-select");
                          }}
                        >
                          <DialogItem
                            label="Theme"
                            icon="theme"
                            subLabel={selectedTheme}
                          />
                        </button>
                      </div>
                    </div>
                    <div
                      id="mobile-profile-odds-format-no-auth"
                      className={`absolute inset-0 pt-3 w-full h-full transition-all duration-150 ease-in-out ${
                        menu === "odd-format"
                          ? "translate-x-0 pointer-events-auto"
                          : "translate-x-full pointer-events-none"
                      }`}
                    >
                      <button
                        className="w-full"
                        onClick={(e) => handleMenuChange(e, "main")}
                      >
                        <DialogItem label="Back" icon="caret-left" />
                      </button>
                      <button
                        className={`w-full`}
                        onClick={() => {
                          setOddsFormat("american");
                          setMobileProfileMenuOpen(false);

                          // handleMenuChange("main");
                        }}
                      >
                        <DialogItem
                          label="American -200"
                          highlighted={oddsFormat === "american"}
                        >
                          {oddsFormat === "american" && (
                            <div className={list.listIndicator}>
                              <Icon
                                name="checkmark"
                                size="md"
                                className="transition-none"
                              ></Icon>
                            </div>
                          )}
                        </DialogItem>
                      </button>
                      <button
                        className={`w-full `}
                        onClick={() => {
                          setOddsFormat("decimal");
                          setMobileProfileMenuOpen(false);

                          // handleMenuChange("main");
                        }}
                      >
                        <DialogItem
                          label="Decimal 1.5"
                          highlighted={oddsFormat === "decimal"}
                        >
                          {oddsFormat === "decimal" && (
                            <div className={list.listIndicator}>
                              <Icon
                                name="checkmark"
                                size="md"
                                className="transition-none"
                              ></Icon>
                            </div>
                          )}
                        </DialogItem>
                      </button>
                      <button
                        className={`w-full`}
                        onClick={() => {
                          setOddsFormat("fraction");
                          setMobileProfileMenuOpen(false);

                          // handleMenuChange("main");
                        }}
                      >
                        <DialogItem
                          label="Fraction 1/2"
                          highlighted={oddsFormat === "fraction"}
                        >
                          {oddsFormat === "fraction" && (
                            <div className={list.listIndicator}>
                              <Icon
                                name="checkmark"
                                size="md"
                                className="transition-none"
                              ></Icon>
                            </div>
                          )}
                        </DialogItem>
                      </button>
                    </div>
                    <div
                      id="mobile-profile-menu-theme-select"
                      className={`absolute inset-0 pt-3 w-full h-full transition-all duration-150 ease-in-out ${
                        menu === "theme-select"
                          ? "translate-x-0 pointer-events-auto"
                          : "translate-x-full pointer-events-none"
                      }`}
                    >
                      <button
                        className="w-full"
                        onClick={(e) => handleMenuChange(e, "main")}
                      >
                        <DialogItem label="Back" icon="caret-left" />
                      </button>
                      <button
                        className={`w-full`}
                        onClick={() => {
                          setSelectedTheme("light");
                          setMobileProfileMenuOpen(false);
                        }}
                      >
                        <DialogItem
                          label="Light"
                          highlighted={selectedTheme === "light"}
                        >
                          {selectedTheme === "light" && (
                            <div className={list.listIndicator}>
                              <Icon
                                name="checkmark"
                                size="md"
                                className="transition-none"
                              ></Icon>
                            </div>
                          )}
                        </DialogItem>
                      </button>
                      <button
                        disabled
                        className={`w-full`}
                        onClick={() => {
                          setSelectedTheme("dark");
                          setMobileProfileMenuOpen(false);
                        }}
                      >
                        <DialogItem
                          comingSoon
                          label="Dark"
                          highlighted={selectedTheme === "dark"}
                        >
                          {selectedTheme === "dark" && (
                            <div className={list.listIndicator}>
                              <Icon
                                name="checkmark"
                                size="md"
                                className="transition-none"
                              ></Icon>
                            </div>
                          )}
                        </DialogItem>
                      </button>
                    </div>
                  </>
                )}
              </NavigationMenu.Content>
            </NavigationMenu.Item>
          </NavigationMenu.List>
          <NavigationMenu.Viewport
            forceMount={true}
            data-state={mobileProfileMenuOpen ? "open" : "closed"}
            onPointerEnter={(event) => event.preventDefault()}
            onPointerLeave={(event) => event.preventDefault()}
            onPointerMove={(event) => event.preventDefault()}
            className={`z-40 fixed left-0 shadow-elevation-400 bg-white w-full h-auto translate-x-full data-[state=open]:translate-x-0 transition-transform duration-150 ease-in-out overflow-x-hidden top-[var(--navbar-height)] overflow-hidden`}
          ></NavigationMenu.Viewport>
          <div
            onClick={() => setMobileProfileMenuOpen(false)}
            data-state={mobileProfileMenuOpen ? "open" : "closed"}
            className="bg-black/50 data-[state=open]:opacity-100 data-[state=open]:pointer-events-auto opacity-0 pointer-events-none data-[state=open]:animate-overlayShow transition-all data-[state=closed]:animate-overlayHide fixed inset-0 backdrop-blur-sm z-[39] top-20"
          />
        </NavigationMenu.Root>
      </div>
    </>
  );
}

interface ItemProps {
  comingSoon?: boolean;
  icon?: string;
  iconSize?: "xs" | "sm" | "md" | "lg" | "xl" | "2xl";
  label: string;
  subLabel?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  children?: React.ReactNode;
  highlighted?: boolean;
  link?: boolean;
}

function Item(props: ItemProps) {
  return (
    <DropdownMenu.Item
      onClick={(e) => (!props.link ? e.preventDefault() : undefined)}
      className="flex px-1 py-3 gap-2 group hover:bg-surface-600 rounded-md outline-none"
    >
      <div className="flex flex-row justify-between items-center w-full">
        <div className="flex flex-row gap-2 items-center">
          {props.icon && (
            <div className="rounded-full bg-dark w-6 h-6 flex justify-center items-center ">
              <Icon
                name={props.icon}
                className="fill-primary text-primary"
                size={props.iconSize || "sm"}
              />
            </div>
          )}
          <p className="text-sm-medium uppercase flex flex-col gap-y-1 justify-center items-start">
            <span>{props.label}</span>
            {props.subLabel && (
              <span className="text-label-lg-bold text-secondary-400">
                {props.subLabel}
              </span>
            )}
          </p>
        </div>
        {props.children}
      </div>
    </DropdownMenu.Item>
  );
}

function DialogItem(props: ItemProps) {
  return (
    <div
      data-highlighted={props.highlighted ? "true" : undefined}
      className={`${list.listItemMobile} group`}
    >
      <div className="flex flex-row justify-between items-center w-full">
        <div className="flex flex-row gap-4 items-center">
          {props.icon && (
            <div className="rounded-full bg-dark w-10 h-10 flex justify-center items-center">
              <Icon
                name={props.icon}
                className="fill-primary text-primary"
                size={props.iconSize || "lg"}
              />
            </div>
          )}
          <p className="text-lg-medium uppercase flex flex-col gap-y-1 justify-center items-start">
            <span
              className={`${
                props.comingSoon ? "text-secondary-500" : "text-secondary-300"
              }`}
            >
              {props.label}{" "}
              {props.comingSoon ? (
                <span className="italic">(Coming Soon)</span>
              ) : (
                ""
              )}
            </span>
            {props.subLabel && (
              <span className="text-label-lg-bold text-secondary-400">
                {props.subLabel}
              </span>
            )}
          </p>
        </div>
        {props.children}
      </div>
    </div>
  );
}
interface OddsFormatMenuProps {
  menu: string;
  oddsFormat: string;
  handleMenuChange: (v: string) => void;
  setOddsFormat: (v: OddsFormat) => void;
}
function OddsFormatMenu(props: OddsFormatMenuProps) {
  return (
    <div
      className={`absolute inset-0 w-full h-full transition-all duration-150 ease-in-out ${
        props.menu === "odd-format"
          ? "translate-x-0 pointer-events-auto"
          : "translate-x-full pointer-events-none"
      }`}
    >
      <button className="w-full" onClick={() => props.handleMenuChange("main")}>
        <Item label="Back" icon="caret-left" iconSize="xs" />
      </button>
      <button
        className={`${list.listItem} w-full`}
        onClick={() => {
          props.setOddsFormat("american");
          props.handleMenuChange("main");
        }}
      >
        American -200
        {props.oddsFormat === "american" && (
          <div className={list.indicator}>
            <Icon name="checkmark" size="md" className="transition-none"></Icon>
          </div>
        )}
      </button>
      <button
        className={`${list.listItem} w-full`}
        onClick={() => {
          props.setOddsFormat("decimal");
          props.handleMenuChange("main");
        }}
      >
        Decimal 1.5
        {props.oddsFormat === "decimal" && (
          <div className={list.indicator}>
            <Icon name="checkmark" size="md" className="transition-none"></Icon>
          </div>
        )}
      </button>
      <button
        className={`${list.listItem} w-full`}
        onClick={() => {
          props.setOddsFormat("fraction");
          props.handleMenuChange("main");
        }}
      >
        Fraction 1/2
        {props.oddsFormat === "fraction" && (
          <div className={list.indicator}>
            <Icon name="checkmark" size="md" className="transition-none"></Icon>
          </div>
        )}
      </button>
    </div>
  );
}

export default UserDropdown;
