"use client";
import React from "react";
import * as RadixDialog from "@radix-ui/react-dialog";
import * as RadioGroup from "@radix-ui/react-radio-group";
import Icon from "../primitives/icon";
import Button from "../primitives/button";
import * as Form from "@radix-ui/react-form";
import { useUser } from "@clerk/nextjs";
import { getBettorProfile } from "@/utils/bettor";

export default function Feedback({ color }: { color: "light" | "dark" }) {
  const { isSignedIn, user, isLoaded } = useUser();
  const [reaction, setReaction] = React.useState<string>("");
  const [message, setMessage] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [bettorEmail, setBettorEmail] = React.useState<string>("");
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | null>(null);
  const [submitted, setSubmitted] = React.useState<boolean>(false);
  const disabled = submitting || reaction === "";

  async function fetchEmail() {
    if (isSignedIn && user?.username) {
      try {
        const profile = await getBettorProfile("username", user.username);
        // console.log("profile:", profile);
        if (profile.details.email) {
          setBettorEmail(profile.details.email);
        }
      } catch (error) {
        console.error("Failed to fetch profile:", error);
      }
    }
  }
  React.useEffect(() => {
    if (localStorage.getItem("feedbackSubmitted")) {
      setSubmitted(true);
      return;
    }
    fetchEmail();
  }, [isSignedIn, user, isLoaded]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setSubmitting(true);
    setError(null);

    try {
      const response = await fetch(
        `${process.env.NEXT_PUBLIC_API_URL}/api/feedback`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            type: reaction,
            message,
            email: bettorEmail || email,
          }),
        }
      );

      console.log("feedback response", response);

      if (!response.ok) {
        throw new Error("Failed to submit feedback");
      }

      setReaction("");
      setMessage("");
      setEmail("");
      setSubmitted(true);
      localStorage.setItem("feedbackSubmitted", "true");
    } catch (err) {
      console.error(err);
      setError("Failed to submit feedback. Please try again.");
    } finally {
      setSubmitting(false);
    }
  };

  const colors =
    color === "light"
      ? "bg-surface-400 text-secondary-300"
      : "bg-[#3A3C3F] text-white";

  if (isLoaded) {
    return (
      <RadixDialog.Root>
        <RadixDialog.Trigger
          className={`${colors} rounded-md w-full text-title-bold uppercase flex items-center justify-center gap-2 h-10 px-5 mt-4`}
        >
          <span>Share Feedback</span>
          <Icon name="chat-bubble" size="sm" />
        </RadixDialog.Trigger>
        <RadixDialog.Portal>
          {/* ROOT */}
          <div className="fixed inset-0 z-50">
            <RadixDialog.Overlay className="bg-black/25 data-[state=open]:animate-overlayShow flex justify-center items-center -z-[1] h-full backdrop-blur-sm">
              {/* SCROLLABLE */}
              <div className="flex justify-center items-center w-full h-full">
                <RadixDialog.Content onPointerDownOutside={(e) => e.preventDefault()} className="z-50 m-2 bg-[radial-gradient(#222326,_#131517)] overflow-x-hidden rounded-xl relative shadow-elevation-400 focus:outline-none max-h-[calc(100%_-_64px)] overflow-y-auto p-6 w-full md:min-w-[384px] max-w-[384px]">
                  <div className="absolute inset-0 z-[-1] bg-[url('/images/feedback/feedback-bg.png')] bg-top w-full h-full bg-no-repeat"></div>
                  <div className="absolute top-5 right-6">
                    <RadixDialog.Close asChild>
                      <Button variant="circle" color="tertiary" size="xs">
                        <Icon name="close" size="xs" />
                      </Button>
                    </RadixDialog.Close>
                  </div>
                  {submitted ? (
                    <div className="flex flex-col gap-2 items-center pt-4">
                      <Icon
                        name="conversation"
                        size="2xl"
                        className="text-white"
                      />
                      <div className="text-title-lg-bold text-center uppercase text-white">
                        Thanks for your feedback!
                      </div>
                      <div className="text-body-sm text-center text-secondary-600">
                        Your feedback will help enhance the <br></br> Moneyline
                        experience 😄
                      </div>
                      <RadixDialog.Close asChild className="mt-8">
                        <Button size="md" className="w-full">
                          <div className="text-center w-full">Close</div>
                        </Button>
                      </RadixDialog.Close>
                    </div>
                  ) : (
                    <>
                      <div className="flex flex-col gap-2">
                        <div className="text-title-lg-bold uppercase text-white">
                          Share your experience
                        </div>
                        <div className="text-body-sm text-secondary-600">
                          Share your thoughts and experience with us!
                        </div>
                      </div>
                      <Form.Root onSubmit={handleSubmit}>
                        <RadioGroup.Root
                          className="grid grid-cols-3 gap-4 mt-6"
                          value={reaction}
                          onValueChange={setReaction}
                          aria-label="View density"
                        >
                          <div className="flex flex-col items-center">
                            <RadioGroup.Item
                              className="w-full data-[state=checked]:border-primary-500 border-[2px] border-secondary-500 rounded-lg h-[110px]"
                              value="bad"
                              id="r1"
                            >
                              <div className="flex flex-col gap-3 items-center">
                                {/* <img
                                src="/images/feedback/bad-feedback.png"
                                height={24}
                                width={24}
                              /> */}
                                <span className="text-[24px]">😔</span>
                                <span className="text-white text-title-sm-medium">
                                  Bad
                                </span>
                              </div>
                            </RadioGroup.Item>
                          </div>
                          <div className="flex flex-col items-center">
                            <RadioGroup.Item
                              className="w-full data-[state=checked]:border-primary-500 border-[2px] border-secondary-500 rounded-lg h-[110px]"
                              value="good"
                              id="r1"
                            >
                              <div className="flex flex-col gap-3 items-center">
                                {/* <img
                                src="/images/feedback/good-feedback.png"
                                height={24}
                                width={24}
                              /> */}
                                <span className="text-[24px]">🙂</span>
                                <span className="text-white text-title-sm-medium">
                                  Okay
                                </span>
                              </div>
                            </RadioGroup.Item>
                          </div>
                          <div className="flex flex-col items-center">
                            <RadioGroup.Item
                              className="w-full data-[state=checked]:border-primary-500 border-[2px] border-secondary-500 rounded-lg h-[110px]"
                              value="love"
                              id="r1"
                            >
                              <div className="flex flex-col gap-3 items-center">
                                {/* <img
                                src="/images/feedback/love-feedback.png"
                                height={24}
                                width={24}
                              /> */}
                                <span className="text-[24px]">😄</span>
                                <span className="text-white text-title-sm-medium">
                                  Love it!
                                </span>
                              </div>
                            </RadioGroup.Item>
                          </div>
                        </RadioGroup.Root>
                        <Form.Field name="message" className="mt-2">
                          <Form.Control asChild>
                            <textarea
                              rows={4}
                              className="w-full border text-input text-dark py-3 px-4 rounded-md flex justify-between items-center outline-none placeholder:text-secondary border-input-grey data-[valid=true]:border-success-300 data-[invalid=true]:border-error-300 hover:border-dark focus:border-dark transition-all duration-100 ease-in-out"
                              placeholder="Tell us more (optional)"
                              value={message}
                              onChange={(e) => setMessage(e.target.value)}
                            />
                          </Form.Control>
                          {!bettorEmail && (
                            <Form.Control asChild>
                              <input
                                className="w-full border text-input text-dark py-3 px-4 rounded-md flex justify-between items-center outline-none placeholder:text-secondary border-input-grey data-[valid=true]:border-success-300 data-[invalid=true]:border-error-300 hover:border-dark focus:border-dark transition-all duration-100 ease-in-out mt-2"
                                placeholder="Email (optional)"
                                value={email}
                                name="email"
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            </Form.Control>
                          )}
                        </Form.Field>
                        <Form.Submit
                          disabled={disabled}
                          className="btn btn-md btn-filled-primary mt-6 w-full"
                        >
                          <span className="w-full text-center">Submit</span>
                        </Form.Submit>
                      </Form.Root>
                    </>
                  )}
                </RadixDialog.Content>
              </div>
            </RadixDialog.Overlay>
          </div>
        </RadixDialog.Portal>
      </RadixDialog.Root>
    );
  }
}
