"use client";
import React from "react";

export interface AvatarProps extends React.HTMLAttributes<HTMLDivElement> {
  img?: string;
  border?: number;
  size?: AvatarSizeVariant;
}
type AvatarSizeVariant = "sm" | "md" | "lg" | "xl";
type AvatarVariance = {
  [key in AvatarSizeVariant]: string;
};
const avatarVariance: AvatarVariance = {
  sm: "min-w-[1.5rem] min-h-[1.5rem] w-6 h-6",
  md: "min-w-[2rem] min-h-[2rem] w-8 h-8",
  lg: "min-w-[2.5rem] min-h-[2.5rem] w-10 h-10",
  xl: "min-w-[5rem] min-h-[5rem] w-20 h-20",
};

const sizeVariance = {
  sm: 24,
  md: 32,
  lg: 40,
  xl: 80,
  "2xl": 500,
};
function Avatar(props: AvatarProps) {
  const [loading, setLoading] = React.useState(true);
  const { img, border, size, ...rest } = props;
  const classes: string = `rounded-full ${avatarVariance[props.size || "md"]} ${
    props.className
  }`;
  return (
    <div className={`relative ${classes}`}>
      {loading && (
        <div className="absolute inset-0 bg-secondary-400 animate-pulse rounded-full"></div>
      )}
      <img
        {...rest}
        src={`${props.img || "/placeholder/profile-green.png"}`}
        className={`${avatarVariance[props.size || "md"]} rounded-full`}
        width={sizeVariance[props.size || "md"]}
        height={sizeVariance[props.size || "md"]}
        onLoad={() => setLoading(false)}
      />
    </div>
  );
}

export interface AvatarGroupProps extends React.HTMLAttributes<HTMLDivElement> {
  avatars: string[];
  dark?: boolean;
  side?: "left" | "right";
  label?: React.ReactNode;
  value: number;
}

function Group(props: AvatarGroupProps) {
  const { avatars, dark, side, label, value, className, ...rest } = props;
  const classes: string = `flex flex-row items-center gap-2 flex-wrap justify-center ${props.className}`;
  return (
    <div {...rest} className={classes}>
      {props.value > 0 && props.avatars.length > 0 && (
        <div className="flex flex-row">
          <Avatar size="sm" img={props.avatars[0]}></Avatar>
          {props.value > 1 && props.avatars.length > 1 && (
            <Avatar size="sm" img={props.avatars[1]} className="-ml-3"></Avatar>
          )}
          {props.value > 2 && props.avatars.length > 2 && (
            <Avatar size="sm" img={props.avatars[2]} className="-ml-3"></Avatar>
          )}
          {props.value > 3 && (
            <div className="-ml-3 min-w-6 h-6 px-2 py-1 text-label-sm-bold bg-primary text-dark rounded-full flex justify-center items-center z-[1]">
              +{props.value - 3}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
Avatar.Group = Group;
export default Avatar;
