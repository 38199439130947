"use client";
import Footer from "@/components/layout/footer";
import Header from "@/components/layout/header";
import React from "react";
import { usePathname } from "next/navigation";
import { MenuProvider } from "./menu";
import AuthProvider from "@/app/(public)/drawer";
import { iconDefsPath } from "@/components/primitives/icon";

const ShellContext = React.createContext<{
  setShouldScrollToTop: (value: boolean) => void;
  isDialogOpen: boolean;
  setIsDialogOpen: (value: boolean) => void;
} | null>(null);

export const useShellContext = () => {
  const context = React.useContext(ShellContext);
  if (!context) {
    throw new Error("useShellContext must be used within a ShellProvider");
  }
  return context;
};

export default function ShellProvider({
  sportsbooks,
  legalStatesItems,
  children,
}: {
  sportsbooks: any;
  legalStatesItems: any;
  children: React.ReactNode;
}) {
  const pathname = usePathname();
  const [previousPath, setPreviousPath] = React.useState("");
  const viewportRef = React.useRef<HTMLDivElement>(null);
  const [shouldScrollToTop, setShouldScrollToTop] = React.useState(false);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  React.useEffect(() => {
    const svgSprite = document.createElement('img');
    svgSprite.src = iconDefsPath;
    document.body.appendChild(svgSprite);
  }, []);

  const specialRoutes = [
    "/scores/",
    "/standings",
    "/profile",
    "/teams/",
    "/picks",
    "/betting-guide/",
    "/game",
  ];

  const isSpecialRoute = (path: string) => {
    return specialRoutes.some((route) => path.includes(route));
  };
  React.useEffect(() => {
    const isScrollTopRoute = () => {
      const onSpecialRouteNow = isSpecialRoute(pathname);
      const wasOnSpecialRouteBefore = isSpecialRoute(previousPath);
      return (
        (onSpecialRouteNow && !wasOnSpecialRouteBefore) ||
        (!onSpecialRouteNow && wasOnSpecialRouteBefore) ||
        (!onSpecialRouteNow && !wasOnSpecialRouteBefore)
      );
    };
 

    if (shouldScrollToTop || isScrollTopRoute()) {
      window.scrollTo(0, 0);
      setShouldScrollToTop(false);
    }
    setPreviousPath(pathname);
  }, [pathname, previousPath]);

  return (
    <>
      <ShellContext.Provider 
        value={{ setShouldScrollToTop, isDialogOpen, setIsDialogOpen }}
      >
        <div ref={viewportRef}>

        <MenuProvider>
          <AuthProvider>
            <Header sportsbooks={sportsbooks} legalStates={legalStatesItems} />
            <div
              className="bg-surface-600 min-h-screen flex flex-col bg-top"
              style={{
                backgroundImage: `url(/images/bettor/profile-bg.png)`,
              }}
              >
              <div className="pb-16 flex-grow min-h-screen">{children}</div>
              <Footer />
            </div>
          </AuthProvider>
        </MenuProvider>
              </div>
      </ShellContext.Provider>
    </>
  );
}
