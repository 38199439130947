import React, { HTMLAttributes } from "react";
import cn from "classnames";
import Button from "./button";

export interface CardProps extends HTMLAttributes<HTMLDivElement> {
  custom?: boolean;
  children?: React.ReactNode;
  dark?: boolean;
  size?: CardSize;
  header?: CardHeaderProps;
}

export interface CardHeaderProps {
  title: React.ReactNode;
  action?: React.ReactNode;
  team?: string;
}
type CardSize = "xs" | "sm" | "md" | "xl";

type CardVariance = {
  base: string;
  size: {
    [key in CardSize]: string;
  }
}

export const CARD_VARIANCE: CardVariance = {
  base: "rounded-xl flex flex-col overflow-hidden relative",
  size: {
    xs: "p-2",
    sm: "p-4",
    md: "pt-4 px-4 pb-6",
    xl: "p-6",
  }
};

function Card(props: CardProps) {
  const classes: string = cn(
    `${
      props.dark ? "bg-dark" : "bg-surface shadow-card"
    } ${CARD_VARIANCE.base}`,
    !props.custom && CARD_VARIANCE.size[props.size || "md"],
    props.className
  );
  return (
    <div className={classes}>
      {props.header && (
        <>
        {props.header.team && (
          <span className="text-label-sm-medium text-secondary uppercase">
            {props.header.team}
          </span>
        )}
        <div className="flex flex-row flex-wrap justify-between mb-2 gap-x-2 gap-y-2 items-center z-[1]">
          {props.header.title && (
            <div className={`flex text-title-bold items-center min-[360px]:text-headline-bold whitespace-nowrap gap-2 uppercase ${props.dark ? 'text-white' : 'text-dark'} items-center`}>
              <h1 className="flex flex-row gap-1 items-center">{props.header.title}</h1>
            </div>
          )}
          {props.header.action && (
            <div className="flex items-center gap-1 shrink justify-end">
              {typeof props.header.action === "string" ? (
                <Button variant="card">{props.header.action}</Button>
              ) : (
                <>{props.header.action}</>
              )}
            </div>
          )}
        </div>
          </>
      )}
      {props.children}
    </div>
  );
}

interface CardTrimProps {
  fromColor: string;
  toColor: string;
}
function Trim(props: CardTrimProps) {
  return (
    <div
      className="after:bg-moneyline-trim after:bg-cover after:z-[2] after:absolute after:bottom-0 after:w-full after:h-[574px] after:bg-center after:-top-[400px] after:rotate-12 overflow-hidden absolute bottom-0 h-2 w-full z-[1] left-0 right-0"
      style={{
        background: `linear-gradient(293deg, #${props.fromColor} 22.6%, #${props.toColor} 73.6%), #FFFFFF`,
      }}
    ></div>
  );
}

export interface CardGroupProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  header?: CardGroupHeaderProps;
}
interface CardGroupHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  headline?: string | JSX.Element;
  subtitle?: string;
  caption?: string | JSX.Element;
  action?: string | JSX.Element;
}
function Group(props: CardGroupProps) {
  return (
    <div {...props} className={cn(`gap-6 flex flex-col`, props.className)}>
      {props.header && (
        <div className="pt-6 mb-4 gap-y-4 gap-x-2 flex items-center justify-between flex-wrap">
          <div className="flex flex-col">
            {props.header.subtitle && (
              <h3 className="text-secondary-400 text-label-lg-bold uppercase mb-1">
                {props.header.subtitle}
              </h3>
            )}
            {props.header.headline && (
              <h1 className="text-headline-lg text-dark uppercase whitespace-nowrap">
                {props.header.headline}
              </h1>
            )}
            {props.header.caption && (
              <p className="text-base normal-case pt-6">{props.header.caption}</p>
            )}
          </div>
          <div className="flex justify-start overflow-x-auto overflow-y-hidden">
            {props.header.action && (
              <div className="flex justify-end items-end flex-row gap-4">
                {props.header.action}
              </div>
            )}
          </div>
        </div>
      )}
      {props.children}
    </div>
  );
}


Card.Group = Group;
Card.Trim = Trim;
export default Card;
